html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
q,
em,
img,
small,
strong,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none
}
:focus {
    outline: 0;
}
input,
textarea {
    margin: 0;
    outline: 0;
}
textarea {
    overflow: auto;
    resize: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}
/* Default Font Styles
______________________*/
body,
input,
select,
textarea,
p,
a,
b {
    font-family: 'Lato', sans-serif;
    color: #000;
    line-height: 1.4;
}
.search_box button:hover{
     background-image: linear-gradient(
-225deg
 ,#fddd6e 35%,#f5cf49 100%);
}
.dashboard_body,#invoice_header{
     margin: 0;
     background: url(../images/pos-banner.jpg);
     background-position: bottom;
     background-size: cover;
     background-repeat: no-repeat;
     height: 100vh;
     overflow: hidden;
}
*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* Links
______________________*/
a {
    text-decoration: none !important;
}
a:hover {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}
/* p, blockquote, address
______________________*/
p {
    font-size: 18px;
    margin-bottom: 20px;
}
a:hover,
a:focus {
    text-decoration: none;
}
.row{margin: 0;}
/*pos*/
.pos_main{
    margin: 16px 85px !important;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 25%);
    border-radius: 14px;
    background-image: linear-gradient(
-225deg
 ,#353535 35%,#3d3d3d 100%);
    padding: 0;
}
.pos_black_box{
    padding: 20px 13px 20px 28px;
    height: 94vh;
}
.search_box button{
    color: #fff;
}
.logo_box{
    padding: 3px 0 18px;
}
.voucher_error_message{

    text-align: left;
    color: #fff;
    font-size: 11px;
    position: relative;
    top: -10px;

}
button:focus{
    outline: none;
}
.logo_box img{

width: 142px;
}
.search_box{
    position: relative;
}
.refresh_img_container{
    position: relative;
}
.refresh_img_container .item_refresh {
    padding: 4px 0 0;
    position: absolute;
    left: 4px;
    top: 0px;
    height: 18px;
}
.img_tooltip_cart{
    position: absolute;
    width: 20px;
    top: 15px;
    left: 164px;
}
.search_box svg{
    position: absolute;
    width: 34px;
    left: 39px;
    top: 7px;
}
.search_box input{
    width: 92%;
    border-radius: 35px;
    border: none;
    padding: 0 65px;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 52%);
    height: 48px;
    font-size: 18px;
    vertical-align: middle;
}
table.custom_table_sale{
    width: 100%;
    max-width: 100%;
    background: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

table.custom_table_sale tr th:fisrt-child{
 border-radius: 0 0 0 0;
}
.main_table_col {
    width: 97%;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
    padding: 0;
}
table.custom_table_sale tfoot {
    background: #3180d2;
    color: #fff;
}
table.custom_table_sale tr th:nth-child(3){
    min-width: 120px;
}
table.custom_table_sale tr th,table.custom_table_sale tr td{
   padding: 5px;
   font-size: 12px;

}
.search_box button,.inner_qty_box a{
    border: none;
    border-radius: 35px;
    padding: 13px 37px;
    margin: 0 0 0 12px;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 52%);
    background-image: linear-gradient(
    -225deg
     ,#417604 35%,#4c8808 100%);
    vertical-align: middle;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    right: 21px;
}
.search_box button:hover{
    color: #000;
}

/*tabs*/
ul.tabs{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
ul.tabs li a{
    display: inline-block;
    padding: 13px 8px 12px 27px;
    width: 100%;
}
ul.tabs li a{
    background: none;
    color: #fff !important;
    position: relative;
    display: inline-block;

    cursor: pointer;
    width: 100%;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 25%);
    border-radius: 4px 0 0 4px;
    background-image: linear-gradient(
-225deg
 ,#4d4d4d 35%,#4d4d4d 100%);
    margin: 0 0 11px;
    font-size: 12px;
}
ul.tabs li .current{
    box-shadow: 1px 12px 31px rgb(27 27 27 / 52%);
    background-image: linear-gradient(
-225deg
 ,#417604 35%,#447502 100%);
    color: #fff;
}
ul.tabs li .current:after{
    border-top: solid #447502 21px;
}
ul.tabs li a:after {
    content: '';
    display: block;
    border-left: solid transparent 22px;
    border-right: solid transparent 22px;
    border-top: solid #4d4d4d 21px;
    top: 10px;
    height: 0;
    right: -32px;
    margin-left: 0;
    position: absolute;
    width: 0;
    transform: rotate(
270deg
 );
}
.tab-content{
    display: none;
    padding: 2px 5px 4px;
}
.tab-content.current{
    display: inherit;
}
.sidebar_left {
    padding: 0 29px 0 0;
    overflow-y: scroll;
    height: 70vh;
    overflow-x: hidden;
}
.contents_right{
    padding-right: 0;
}
.products_listing ul{
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, minmax(163px, 1fr)); */
    /* width: 100%; */
    margin: 0;
}
.products_listing ul li{
    display: inline-block;
    width: 178px;
    margin: 0 5px 0 0;
    cursor: pointer;
    position: relative;
}
.products_listing .product_box,.inner_boxes{
    background: #fefefe;
    margin: 0 0 4px;
    padding: 11px;
    border-radius: 5px;
    overflow: hidden;
}
.products_listing .product_box h4 {
    font-weight: bold;
    margin: 0 0 4px;
    color: #000;
    font-size: 14px;
    height: 16px;
    display: -webkit-box;
    max-width: 142px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.products_listing .product_box b{
    font-size: 13px;
    color: #67b50e;
    display: block;
    margin: 6px 0 15px;
}
.products_listing .product_box h3{
    font-size: 22px;
    color: #4d4d4d;
}
.products_listing .product_box .product_label{
    display: block;
    width: 44px;
    right: 6px;
    overflow: hidden;
    height: 48px;
    object-fit: contain;
}
.sidebar_left li img {
    width: 15px;
    vertical-align: middle;
    position: absolute;
    left: 7px;
}
.products_listing{
    overflow-y: auto;
    height: 67vh;
}
.products_listing::-webkit-scrollbar-track,
.order_list_scrol::-webkit-scrollbar-track{
    border-radius: 35px;
    background-color: #fff;
}
.products_listing::-webkit-scrollbar,
.order_list_scrol::-webkit-scrollbar{
    width: 8px;
    background-color: #fff;
}
.products_listing::-webkit-scrollbar-thumb,
.order_list_scrol::-webkit-scrollbar-thumb{
    border-radius: 35px;
    background-color: #e2bc3c;
}


.sidebar_left::-webkit-scrollbar-track{
    border-radius: 35px;
    background-color: #4d4d4d;
}
.sidebar_left::-webkit-scrollbar{
    width: 8px;
    background-color: #4d4d4d;
}
.sidebar_left::-webkit-scrollbar-thumb{
    border-radius: 35px;
    background-color: #222;
}

.orange_fixed {
    position: fixed;
    right: -64px;
    z-index: 9999;
    bottom: -28px;
}
.orange_fixed img {
    width: 189px;
}
.quantity_box_pos{
    display: inline-block;
    margin-right: 9px;
}
#product_row p {
    font-weight: 600;
}
.loader_middle{
    text-align: center;
    padding: 50px 0;
}
.loader_middle img{
    width: 100px;
}
#vale_box .value_box_pos {
    width: 36px;
    height: 24px;
    padding: 3px 3px 0;
    display: inline-block;
}
.quantity_box_pos .minus_plus_pos {
    border: 1px solid #f3cf59;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: #f3cf59;
    color: #000;
    line-height: 1;
}
.main_body_style #uder_eng_language ,.bg_detail .cata-sub-slider {
    background: unset;
    padding: 0;
}
.bg_detail .cart-page h3{
    color: #fff;
}
.bg_detail .product_layout_n {
    background: #fff !important;
        border-radius: 5px;
    margin: 10px 0;
}
.bg_detail .quantity_box .minus_plus_pos {
    border: 1px solid #f3cf59;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    background: #f3cf59;
    color: #000;
    line-height: 1;
}
.main_list_list .table ,.main_list_list .invoice-left-right{
background: #ffff;
    border-radius: 5px;
}
.main_list_list .search_porduct input {
    position: unset;
    width: 100%;
    margin-top: 23px;
}
.select_items_pdt  h3{
     color: #fff;
}
.main_list_list .approved label{
 color: #fff;
}
.main_list_list .Select_language a{
    background: #ffffff;
    color: #3a3a3a;
}
.main_list_list .invoice-space {
    background: #ffff;
    border-radius: 5px;
}
.bg_detail .quantity_box {

}
.quantity_box_pos {
    display: inline-block;
    margin-right: 9px;
}
.bg_detail .product_img_n img {
    width: 60px !important;
    height: 60px;
    object-fit: cover;
    border-radius: unset;
    border: unset;
}
.bg_detail .cata-sub-slider li a {
    display: inline-block;
    background: #f3cf59;

    box-shadow: unset;
}
.bg_detail .main_body_n ,.bg_detail  .product_layout_n {
    background: unset;
}
.bg_detail .search_porduct input{

}
.main_body_style .Select_language h3 {

    color: #fff;

}
.main_body_style .main_body {
    background: unset !important;
    padding: 0;
}
.main_body_style button:hover, .main_body_style button {
    background-image: linear-gradient(
-225deg
 ,#fddd6e 35%,#f5cf49 100%);
    border: unset;
    color: #000;
    box-shadow: unset;
}
.bottom-nav .img_tooltip_cart{
        width: 25px;
    padding: 0;
    cursor: pointer;
    position: absolute;
    margin: unset;
    left: unset;
    right: 18%;
    top: 12px;
    }
    .refresh_img_container .item_refresh {
    padding: 4px 0 0;
    position: absolute;
    left: 3px;
    top: 1px;
    height: 20px;
}
.quantity_box_pos .minus_plus_pos, .quantity_box_pos .value_box_pos {
    margin-bottom: 0;
    font-size: 18px;
    cursor: pointer;
}
.quantity_box_pos .value_box_pos {
    text-align: center;
    width: 45px;
    height: 24px;
    margin: -1px 0px;
    border-left: none;
    border-right: none;
    border: 1px solid #dcd8d8;
    vertical-align: middle;
    padding: 0 3px;
    font-size: 12px;
}
.qty-minus_pos{
    border-radius: 19px 0 0 19px;
}
.qty-plus_pos{
    border-radius: 0 19px 19px 0;
}
.shdown_box {
    position: absolute;
    bottom: 6px;
    right: 5px;
}
.barcode {
    padding: 6px 0 0;
}
.barcode h4 {
    font-size: 13px;
    color: #e4b20a;
    font-weight: bold;
}
.barcode p {
    font-size: 12px;
    color: #fff;
    margin: 3px 0 0;
}
.barcode img {
    width: 83px;
    background: #fff;
    margin: 6px 0;
    border-radius: 2px;
    padding: 0 3px 3px 0;
}
.overlay_body_box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 9;
    background: #ebb62f9e;
    width: 100%;
}
.product_btn,.overlay_body_box,.close_btn_product{
    display: none;
}
.btm_slide_popup {
    position: fixed;
    bottom: -444px;
    left: 0;
    right: 0;
    z-index: 999;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    width: 30%;
    padding: 78px 0 108px;
    display: none;
    border-radius: 31px 31px 0 0;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 25%);
}
.inner_qty_box input,.total_payment .inner_qty_box input {
    width: 75%;
    height: 56px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #c7c7c7;
    padding: 0 5px;
    margin: 0px 0 19px;
    font-size: 23px;
    text-align: center;
}
.inner_qty_box input:focus {
    /* border: 2px solid #f1c73d; */
    /* border-radius: 35px; */
}
.inner_qty_box h3 {
    font-size: 26px;
    color: #417604;
    font-weight: bold;
    padding: 0 68px;
}
.inner_qty_box b {
    display: block;
    font-size: 25px;
    margin: 8px 0;
}
.inner_qty_box a {
    padding: 16px 70px;
    box-shadow: 1px 12px 31px rgb(27 27 27 / 33%);
    background-image: linear-gradient(
-225deg
 ,#f1c73d 35%,#f1c73d 100%);
    font-weight: 600;
    color: #333232;
    margin: 22px 0 0;
    font-size: 20px;
    position: static;
    display: inline-block;
    text-transform: uppercase;
}
.white_overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: none;
    height: 100%;
    background: #bab9b7ba;
}
.close_popup {
    position: absolute;
    top: -12px;
    right: -10px;
    background: #efeaea;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: 5px 0 0;
    cursor: pointer;
}
.close_popup img {
    width: 24px;
}
.bottom-nav {
    height: 48px;
    width: 100vw;
    background-color: #fddc6b;
    position: absolute;
    bottom: 0;
    left: 0;
}
.bottom-nav .fab {
    height: 68px;
    width: 68px;
    background-color: #ffffff;
    border-radius: 100%;
    border: 6px solid #383838;
    margin: auto;
    position: relative;
    top: -42px;
    text-align: center;
    line-height: 64px;
}
.bottom-nav img {
    width: 58px;
    padding: 0;
    cursor: pointer;
    margin: 0 0 0 -1px;
}
.head_title {
    text-align: right;
    padding: 7px 0;
}
.head_title h4{
    color: #fff;
    font-size: 17px;
}
.pos_dashboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 7px 0 10px;
}
.inner_boxes {
    text-align: center;
    margin: 0 0 18px;
    padding: 15px 15px;
    -webkit-transition:0.8s;
}
.inner_boxes img {
    display: block;
    margin: 0 auto 17px;
    width: 51px;
}
.inner_boxes b {
    color: #989b9e;
    display: block;
    font-weight: bold;
    font-size: 16px;
}
.inner_boxes:hover b,.active_dash_b .inner_boxes b{
    color: #504c4c;
}
.inner_boxes:hover,.active_dash_b .inner_boxes {
    background-image: linear-gradient(
    -225deg
     ,#fddd6e 35%,#f5cf49 100%);
    }
    .inner_boxes:hover img,.active_dash_b .inner_boxes  img{
            filter: grayscale(61%) brightness(41%) sepia(8%) hue-rotate(
    -50deg
     ) saturate(474%) contrast(0.8);
}
.account_bln{
    margin: 0 15px 15px;
    padding: 12px 0;
    -webkit-transition: 0.8s;
        background-image: linear-gradient(
-225deg
 ,#2f80d4 35%,#337fce 100%);
    border-radius: 7px;
}
.boxes_pos .account_bln{
    margin-right: 0;
}
.balance_box{
    padding: 0 25px;
    text-align: center;
}
.balance_box b{
    font-size: 28px;
    font-weight: bold;
    display: block;
    margin: 0;
}

.balance_box p{
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 12px;
}
.cash_flow{
    border-top: 1px solid #cccccc8a;
    padding: 0 22px 0;
}
.cashflow_month{
    padding: 2px 0;
    text-align: center;
}
.cashflow_month p,.cashflow_month b{
    color: #fff;
}
.cashflow_month b{
    font-weight: bold;
    font-size: 20px;
}
.cashflow_month p{
    margin: 4px 0 4px;
    font-size: 14px;
    font-weight: 600;
}

.balance_box b,.cashflow_month .red_value,.cashflow_month .gren_value,.balance_box p,
.cashflow_month h4{
    color: #fff;
}

.cashflow_month h4{
    font-weight: bold;
    padding: 10px 0 3px;
}
#pos_total {
    background: none;
    border: none;
    width: 88%;
    padding: 0;
}
#pos_total  b {
    color: #4d4d4d;
    margin: 4px 0 0 0;
}
#pos_total   span {
    color: #4d4d4d;
    font-weight: 600;
}
#pos_total .button-loadings {
    /*background: #ffffff;*/
    padding: 10px 3px 10px 32px;
    width: 17%;
    margin: 0;
    font-size: 20px;
    text-align: left;
    background-image: linear-gradient(
-225deg
 ,#2f80d4 35%,#337fce 100%);
}
#pos_total button span {
    color: #fff;
}
#pos_total  .total_amount_box button i {
    font-size: 21px;
    position: absolute;
    right: 36px;
    top: 14px;
}
/*#sale_order_page .pos_black_box {
    height: auto;
}*/
#sale_order_page .main_body {
    background: none !important;
    /* height: 100vh; */
}
#sale_order_page .Select_language {
    padding: 0 14px 52px;
}
#sale_order_page .delivery_address label {
    left: 18px;
    top: -11px;
    font-size: 14px;
    font-weight: 600;
    color: #fafafa;
    background: none;
}
#grand_total{
    font-size: 29px;
    margin: -10px 0 0 3px;
}
#sale_order_page  .delivery_address input, #sale_order_page  .delivery_address select ,
.total_payment input,.delivery_address textarea{
    height: 46px;
    padding: 4px 14px 0;
    margin: 0 0 3px;
    background-image: linear-gradient(
-225deg
 ,#4d4d4d 35%,#4d4d4d 100%);
    border-radius: 4px;
    border: none;
    color: #fff;
}
#sale_order_page .Request_door button{
    font-size: 18px;
    width: 100%;
    background-image: linear-gradient(
-225deg
 ,#fddd6e 35%,#f5cf49 100%);
    border: none;
    color: #000;
    box-shadow: none;
    font-weight: bold;
}
#sale_order_page .login_screen,#sale_order_page .logo_screen_n {
    padding: 0;
    background: none;
}
.Continue_arrow{
    margin-top: 1px;
    vertical-align: middle;
}
.edit_form_data {
    display: inline-block;
    padding: 0 0 0 20px
}
.edit_form_data img {
    vertical-align: top;
    width: 27px !important;
    margin: -13px 0 0;
    cursor: pointer;
}
#pos_total .total_amount_box span{
    padding: 0;
}
#pos_total .total_amount_box button span{
    padding-top: 0;
}
.no_records{
    text-align: center;
    color: #fff;
    padding: 10px 0 0;
}
.empty_cart_bx{
    text-align: center;
    padding: 58px 0;
}
.empty_cart_bx img{
        width: 119px;
}
.head_amount {
    text-align: center;
    padding:13px 0 0;
}
.head_amount b {
    font-size: 37px;
}
.head_amount b,.head_amount p {
    color: #fff;
}
.head_amount p {
    font-size: 18px !important;
    margin: 0;
}
.view_history_inv {
    padding: 10px 18px;
    background: #e8f2f7;
    position: relative;
}
.view_history_inv h4 {
    color: #7b7b7b;
    font-size: 16px;
    font-weight: 600;
}
#invoice_listing_p .invoice-space span{
    color: #949292;
    text-align: right;
    margin: 0 7px 0 0;
}
.view_history_inv p {
    color: #949292;
    font-size: 14px !important;
}
.view_history_inv a {
    position: absolute;
    right: 21px;
    top: 20px;
    background: none;
    color: #1894b8;
    font-weight: 600;
}


.invoice_bdr {
    cursor: pointer;
    padding: 8px 16px;
    border-bottom: 1px solid #cccccc7a;
    height: auto;
}
.left_invoice, .right_invoice {
    padding: 0;
}
.left_invoice h4 {
    font-size: 15px;
    font-weight: 600;
}
#invoice_listing_p .invoice-left-right ul li b,.left_invoice h4 {
    color: #7b7b7b;
}
#invoice_listing_p .left_invoice p {
    font-size: 16px;
}

.right_invoice {
    text-align: right;
}
.right_invoice h4 {
    color: #7b7b7b;
    font-weight: 600;
}
.right_invoice p {
    margin: 2px 0 9px;
    color: #909da3;
    font-weight: 500;
    font-size: 15px;
}
.gray_bg_box {
    background: #f5f5f5;
}

.pos_inv_table {
    max-height: 46vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: auto;
    padding-bottom: 82px;
}
.pos_inv_table::-webkit-scrollbar-track{
    border-radius: 35px;
    background-color: #fff;
}
.pos_inv_table::-webkit-scrollbar{
    width: 8px;
    background-color: #fff;
}
.pos_inv_table::-webkit-scrollbar-thumb{
    border-radius: 35px;
    background-color: #7b7b7b;
}
.close_search {
    position: absolute;
    top: 12px;
    right: 108px;
}
.close_search img{
    cursor: pointer;
    width: 25px;
}
.logout_btn {
    position: absolute;
    right: 34px;
    top: 9px;
}
.logout_btn img {
    width: 33px;
    cursor: pointer;
}
.empty_cart_bx {
    width: 1359px;
}
.quantity_box_pos {
    font-size: 13px;
}
#select_pro .pos_black_box{
    padding-top: 7px;
}
#select_pro .user_login_n h3 {
    margin-bottom: 3px;
}
#product_row {
    margin: 11px 13px 1px 0;
    width: 32%;
    float: left;
}
#product_row p {
    font-size: 14px;
    margin-bottom: 0;
}
.invoice_list_page{
    text-align: center;
}
#pos_listgs .user_login {
    padding: 0 27px;
}
#pos_listgs .cart-page {
    margin: 11px 0 12px;
}
#pos_listgs  .cart-page h3 {
    font-size: 20px;
}
#pos_listgs {
    padding: 7px 0 17px;
}
.fixed_loader{
    height: auto !important;
    margin: 0 !important;
    position: fixed;
    bottom: 26px;
    left: 0;
    right: 0;
}
.fixed_loader .loader_on_listing img{
    border-radius: 100%;
    width: 49px;
}
.order_list_scrol {
    overflow-y: auto;
    height: 82vh;
}
.back_dashb .head_title {
    padding: 0;
}
.back_dashb i{
    color: #fff;
    font-size: 37px;
}
.total_payment {
    text-align: left;
    padding: 33px 0 0;
}
.total_rupees {
    text-align: right;
    margin: 0 0 13px;
}
.total_rs_bln input {
    margin: 7px 0 0;
    padding: 0 10px;
    width: 247px;
    vertical-align: middle;
}
.total_rs_bln span{
    color: #fff;
}
.total_rupees b,.total_rupees label{
    color: #2f80d4;
}
.total_rupees b, .total_rs_bln span, .total_rupees label {
    font-size: 40px;
}
.total_payment input::-webkit-input-placeholder ,#sale_order_page .delivery_address input::-webkit-input-placeholder { 
  color: #fff;
}
.total_payment input::-moz-placeholder,#sale_order_page .delivery_address input::-moz-placeholder { 
  color: #fff;
}
.total_payment input:-ms-input-placeholder,#sale_order_page .delivery_address input:-ms-input-placeholder {
  color: #fff;
}
.total_payment input:-moz-placeholder,#sale_order_page .delivery_address input:-moz-placeholder { 
  color: #fff;
}
.next_prev{
    text-align: center;
}
#pos_total.next_prev .prev_btn{
        background-image: linear-gradient( 
-225deg
 ,#417604 35%,#447502 100%);
}
#pos_total.next_prev .next_btn{
    background-image: linear-gradient( 
-225deg
 ,#2f80d4 35%,#337fce 100%);
}
#pos_total.next_prev a button {
    float: none;
    position: relative;
    width: auto;
    padding: 8px 39px;
    border-radius: 35px;
    margin: 0 8px;
}
#pos_total.next_prev a button span {
    color: #333232;
}
#pos_total.next_prev a button i {
    position: static;
    vertical-align: middle;
    margin: -2px 0 0 2px;
}
.next_prev {
    height: 69px !important;
    padding: 13px 0 0 !important;
}
.auto_scrol .delivery_address textarea{
        padding-top: 12px;
}
.checkout_pos .inner_qty_box {
    text-align: center;
    width: 352px;
    margin: 0 auto;
}
.checkout_pos .inner_qty_box input {
    background: none;
    width: 97%;
}
.total_payment b{
    color: #fff;
}
.total_payment span {
    color: #317fd0;
    display: block;
}
.total_payment b{
    font-size: 30px;
}
.total_payment span {
    font-size: 32px;
}
.dashboard_icon{
    padding: 0 0 0 15px;
}


/*responsive*/

@media(max-width: 1200px){
    .container{
        width: 100%;
    }
    .fixed_loader {
    bottom: 16px;
}
    #vale_box .value_box_pos {
    width: 25px;
}
    #product_row {
    width: 31.9%;
}
    #new_selection{
        padding-top: 4px;
    }
    .hamburger {
    left: 31px;
    top: 28px;
}
    #invoice_listing_p #invoice_listing_no{
    text-align: left;
}
#invoice_listing_p #invoice_listing_no b {
    width: 55%;
}
 .bottom-nav .img_tooltip_cart {
    left: unset;
    width: 20px;
    top: 17px;
    right:22%;
}
.empty_cart_bx {
    width: 1025px;
}
    .refresh_img_container .item_refresh {

    left: 8px;
    top: 0px;
    width: 16px;
}
    #invoice_listing_p .invoice_table {
        max-height: 255px;
    }
    /*.pos_inv_table {
        max-height: 33vh;
        padding-bottom: 50px;
    }*/
    #invoice_listing_p .select_items_pdt {
        padding: 14px 16px 18px;
    }
    .sidebar_left {
        padding: 0 26px 0 0;
    }
    .edit_form_data {
        padding: 0;
        position: absolute;
        top: 27px;
        right: -2px;
    }
    .logo_box img {
        width: 124px;
    }
    .bottom-nav {
        height: 48px;
    }
    ul.tabs li a:after {
        border-left: solid transparent 18px;
        border-right: solid transparent 18px;
        border-top: solid #4d4d4d 21px;
        top: 6px;
        right: -28px;
    }
    #pos_total {
        width: 100%;
        padding: 1px 56px 0 18px;
    }
    #pos_total .total_amount_box button i {
        right: 83px;
        top: 16px;
    }
    .orange_fixed img {
        width: 157px;
    }
    ul.tabs li a {
        font-size: 10px;
        padding: 10px 5px 9px 20px;
        margin: 0 0 8px;
    }
    .search_box input {
        width: 92%;
        height: 41px;
    }
    .close_search {
        top: 9px;
        right: 93px;
    }
    .search_box svg {
        width: 28px;
        left: 39px;
        top: 7px;
    }
    .logout_btn {
        right: 34px;
        top: 5px;
    }
    .search_box button {
        padding: 10px 37px;
    }
    .barcode h4 {
        font-size: 10px;
    }
    .barcode p {
        font-size: 10px;
    }
    .barcode img {
        width: 75px;
    }
    .logo_box {
        padding: 3px 0 5px;
    }
    .pos_black_box {
        padding: 20px 13px 20px 15px;
    }
    .products_listing ul li {
        width: 185px;
    }
    .products_listing .product_box {
        margin: 0 0 9px;
    }
    .products_listing {
        overflow-x: hidden;
    }
    .shdown_box {
        bottom: 12px;
    }
    .btm_slide_popup {
        width: 37%;
        padding: 78px 0 65px;
    }
    .balance_box b {
        font-size: 23px;
    }
    .cashflow_month b {
        font-size: 17px;
    }
    .inner_boxes {
        margin: 0px 0 12px;
    }
    .account_bln {
        padding: 7px 0;
    }
    .inner_boxes img {
        margin: 0 auto 10px;
        width: 41px;
    }
    .pos_main {
        margin: 16px 14px !important;
    }
    .sidebar_left li img {
        width: 12px;
        left: 5px;
    }
    .sidebar_left {
        height: 71vh;
    }
    .products_listing .product_box {
        margin: 0;
    }

}


@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .dashboard_icon {
        padding: 0 6px 0 10px;
    }
    .boxes_pos .account_bln {
        margin-right: 6px;
    }
    .bottom-nav .img_tooltip_cart {
        right: 26%;
    }
        .empty_cart_bx {
        width: 768px;
    }
    #select_pro .logo_screen_n {
        max-width: 100%;
        margin: 0 auto 0;
        padding: 33px 0 0;
    }
    #product_row {
        width: 47.9%;
    }
    .cata-sub-slider .nav-next-, .cata-sub-slider .nav-prev- {
        top: 57px;
    }
    .cata-sub-slider li {
        margin: 0 6px;
    }
    #invoice_listing_p .select_items_pdt h3 {
        font-size: 18px;
    }
    #invoice_listing_p #order_lists_load {
        margin: 0 ;
    }
    .edit_form_data img {
        width: 27px !important;
        margin: -17px 0 0;
    }
    .sidebar_left {
        height: 75vh;
    }

    #pos_total {
        padding: 1px 12px 0 14px;
    }
    .products_listing {
        height: 69vh;
    }
    .inner_boxes img {
        width: 50px;
    }
    #pos_total .button-loadings {
        padding: 10px 3px 10px 24px;
        width: 24%;
    }
    #pos_total .total_amount_box button i {
        right: 46px;
    }
    .btm_slide_popup {
        width: 47%;
    }
    .pos_main {
        margin: 10px !important;
    }
    .orange_fixed img {
        width: 105px;
    }
    .products_listing ul li {
        width: 184px;
    }
    .logo_box img {
        width: 91px;
    }
    .logo_box {
        padding: 3px 0 11px;
    }
    .pos_black_box {
        padding: 14px 6px 15px 11px;
        height: 96vh;
    }
    ul.tabs li a{
        font-size: 10px;
        padding: 11px 5px 11px 22px;
        margin: 0 0 6px;
    }
    .sidebar_left li img {
        width: 14px;
        left: 5px;
    }
    ul.tabs li:after {
        border-left: solid transparent 19px;
        border-right: solid transparent 19px;
        border-top: solid #4d4d4d 21px;
        top: 8px;
        right: -29px;
    }
    .search_box input {
        width: 89%;
        font-size: 15px;
    }
    .barcode img {
        width: 108px;
    }
    .sidebar_left {
        padding: 0 6px 0 0;
    }
    ul.tabs li a:after {
        display: none;
    }
}


@media(max-width: 767px){
    .container{
        width: 411px;
    }
    .cashreceive{
        padding-top: 30px;
    }

    .empty_cart_bx {
        width: 320px;
        padding: 22px 0 100px;
    }
    ul.tabs li a:after {
        display: block;
    }
    .products_listing ul {
        grid-template-columns: repeat(auto-fill, minmax(169px, 1fr));
    }
    .cashflow_month h4 {
        padding: 6px 0 3px;
    }
    /*.products_listing ul li {
        width: 169px;
    }*/
    .products_listing ul li {
        width: 201px;
    }


}


@media(max-width: 767px){
    .container{
        width: auto;
    }
    #sale_order_scroll{
        overflow-y: auto;
        height: 84vh;
    }
  .auto_scrol .delivery_address input, .auto_scrol .delivery_address select, .auto_scrol .delivery_address textarea {
    margin: 0 0 13px !important;
    height: 44px !important;
}
.checkout_pos .inner_qty_box {
    width: 100%;
}
.total_payment b, .total_payment span {
    font-size: 26px;
}
.total_payment .inner_qty_box {
    padding: 22px 0 0;
}
.checkout_pos .inner_qty_box input {
    width: 78%;
    font-size: 19px;
    height: 47px;
    margin: 7px 0 16px;
}
    #pos_total.next_prev a button {
        padding: 7px 27px 10px;
    }   
    .total_rupees b, .total_rs_bln span, .total_rupees label {
    font-size: 26px;
}
.total_rs_bln input {
    margin: 0;
    height: 37px;
    width: 100%;
    font-size: 14px;
}
.total_rs_bln {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.total_rupees {
    margin: 0 0 13px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding: 0;
}
    .order_list_scrol {
    height: 93vh;
}
.Select_language h3 {
    margin: 13px 0 17px;
    font-size: 17px;
}
    #order_lists_load  .cart-page {
    margin-top: 7px !important;
}
.product_img img {
    width: 50px;
    height: 50px;
}
#order_lists_load .product_contents {
    padding: 0 0 0 18px;
}
#order_lists_load .user_login p {
    font-size: 12px;
    margin: 0;
}
#order_lists_load .product_price {
    padding: 10px 13px 0 0;
}
.product_img {
    padding: 7px 0 3px 5px;
}
    #order_lists_load  .user_login {
    padding: 0;
}
    #product_row {
    width: 100%;
    margin: 7px 0 0;
}
    .img_tooltip_cart{
    position: absolute;
    width: 20px;
    top: 15px;
    left: 164px;
}
#select_pro .quantity_box_pos {
    margin-right: 0px;
}
#select_pro .cart-page {
    padding: 0;
}
.bottom-nav .img_tooltip_cart {
    width: 17px;
    right: 37%;
    left: auto;
    top: 6px;
}
#select_pro .cart-page {
    margin-top: 12px;
}
    .invoice-left-right ul li, .bg-right-inner ul li {
        font-size: 12px;
    }
    .close_search {
        top: 9px;
        right: 17px;
    }
    .edit_form_data {
        top: 24px;
        right: auto;
        left: 17px;
    }
    .logout_head .logout_btn {
        right: 1px;
        top: 4px;
        left: initial;
    }
    .logout_btn {
        right: auto;
        top: -45px;
        left: 87px;
    }
    .logout_btn img {
        width: 28px;
}
    .close_search img {
        width: 19px;
    }
    #invoice_listing_p .select_items_pdt h3 {
        font-size: 16px;
    }
    #invoice_header .hamburger {
        left: 22px;
    }
    .view_history_inv {
        padding: 8px 9px;
    }
    .view_history_inv h4 {
        font-size: 13px;
        width: 187px;
        line-height: 1.3;
    }
    #invoice_listing_p .invoice-left-right ul li, #invoice_listing_p .bg-right-inner ul li {
        padding: 1px 0 2px;
    }
    .view_history_inv a {
        right: 17px;
        top: 17px;
        font-size: 14px;
    }
    .head_amount {
        padding: 7px 0 0;
    }
    .view_history_inv p {
        font-size: 12px !important;
    }
    .head_amount b {
        font-size: 23px;
    }
    #invoice_listing_p .angle_back {
        margin: 0;
        background: transparent;
        color: #fff;
        float: none;
        font-size: 37px;
        position: absolute;
        left: 16px;
        top: -3px;
        width: 100%;
        right: auto;
        padding: 0;
        text-align: left;
    }
    #invoice_listing_p .main_row {
        padding: 6px 9px;
        border: none;
        background: #fff;
        border-bottom: 1px solid #cccccc7a;
    }
    #invoice_listing_p .select_items_pdt {
        padding: 13px 16px 13px;
    }
    .empty_cart_bx img {
        width: 82px;
    }
    .invoice_table table tr td, .invoice_table table tr th {
        font-size: 11px;
        padding: 5px 4px;
    }
    #invoice_listing_p .invoice_table {
        padding: 2px 9px;
    }
    .logo_box img {
        width: 103px;
    }
    .edit_form_data img {
        width: 26px !important;
        margin: -17px 0 0;
    }
    #sale_order_page .Select_language {
        padding: 0 0 22px;
    }
    #sale_order_page .delivery_address input, #sale_order_page .delivery_address select,
    #sale_order_page .delivery_address label {
        font-size: 12px;
    }
    #sale_order_page .delivery_address label {
        left: 18px;
        top: -8px;
    }
    #sale_order_page .delivery_address input, #sale_order_page .delivery_address select {
        margin: 0 0 12px;;
    }
    #sale_order_page .delivery_address {
        margin: 14px 0 0px;
    }
    .inner_boxes {
        padding: 12px 15px 12px;
    }
    .cashflow_month h4 {
        font-size: 14px;
    }
    .products_listing {
        height: 90vh;
    }
    .products_listing .product_box {
        margin: 0 0 4px;
    }
    .products_listing .product_box {
        padding: 9px 11px 11px;
    }
    .bottom-nav {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 52px;
        background-color: #e8ca63;
    }
    .bottom-nav .fab {
        height: 47px;
        width: 47px;
        top: -26px;
        border: 6px solid #3b3b3b;
    }
    #pos_total b {
        margin:0;
        font-size: 22px;
    }
    #pos_total .button-loadings {
        padding: 4px 3px 8px 14px;
        width: 34%;
        margin-top: 0;
        position: relative;
        top: -6px;
    }
    .bottom-nav .fab {
        line-height: 49px;
    }

    #pos_total .total_amount_box > span {
        font-size: 20px;
        margin: -6px 38px 0 0;
    }
    #pos_total .total_amount_box button span {
        font-size: 16px;
        margin: -2px 38px 0 0;
    }
    #pos_total .total_amount_box button i {
        right: 23px;
        top: 13px;
        font-size: 17px;
    }
    #pos_total {
        padding: 0px 12px 0 9px;
    }
    .bottom-nav img {
        width: 39px;
        padding: 0;
        margin: -2px 0 0 -2px;
    }
    .products_listing {
        padding-bottom: 172px;
    }
    .btm_slide_popup {
        width: 94%;
        padding: 63px 0 77px;
    }
    /*.pos_black_box {
        height: auto;
    }*/
    .close_popup {
        top: -6px;
        right: -7px;
    }
    .inner_qty_box h3 {
        font-size: 22px;
        padding: 0 23px;
        line-height: 1.3;
    }
    .inner_qty_box input {
        width: 87%;
        height: 50px;
        margin: 0 0 41px;
    }
    .search_box {
        padding: 0;
    }
    .search_box input {
        width: 100%;
        font-size: 12px;
        padding: 0 0 0 32px;
        height: 35px;
    }
    .search_box svg {
        width: 20px;
        left: 9px;
        top: 8px;
    }
    .search_box button {
        padding: 10px 12px;
        font-size: 11px;
        position: absolute;
        right: 0;
    }
    body {
        height: auto;
        overflow: visible;
    }
    .sidebar_left {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #353535;
        width: 77%;
        padding: 47px 38px 36px 16px;
        z-index: 99;
        height: 100%;
    }
    .contents_right {
        padding: 0;
        margin: 16px 0 0;
    }
    .products_listing::-webkit-scrollbar{
        width: 0;
        background-color: #fff;
    }
    ul.tabs li a {
        font-size: 12px;
        padding: 10px 5px 11px 31px;
        margin: 0 0 10.5px;
    }
    .sidebar_left li img {
        width: 16px;
        left: 8px;
    }
    .barcode h4 {
        font-size: 13px;
    }
    .barcode p {
        font-size: 11px;
        margin: 4px 0 7px;
    }
    .barcode img {
        width: 112px;
    }
    .product_btn {
        display: block;
        position: absolute;
        left: 27px;
        top: 30px;
        cursor: pointer;
    }
    .product_btn img {
        width: 23px;
    }
    .logo_box {
        padding: 0px 0 13px 0;
        float: right;
        width: 88%;
        flex: 88%;
        max-width: 88%;
        text-align: right;
        margin: 0 0 0 30px;
    }
    .close_btn_product{
        display: block;
        position: absolute;
        top: 12px;
        right: 14px;
        cursor: pointer;
    }
    .close_btn_product img{
        width: 25px;
    }
    #dashb_p .logo_box,#dashb_p .search_box{
        width: 48%;
        flex: 48%;
        max-width: 48%;
    }
    #dashb_p .logo_box {
        float: left;
        text-align: left;
        margin: 0 0 0 4px;
    }
    #dashb_p .search_box {
        padding: 0;
    }
    .pos_dashboard {
        max-width: 100%;
        padding: 4px 0;
    }
    .dashboard_icon {
        float: left;
        width: 50%;
        padding: 0 5px;
    }
    .balance_box b {
        font-size: 17px;
    }
    .boxes_pos{
        overflow-y: scroll;
        height: 83vh;
    }
    .inner_boxes {
        margin: 0 0 9px;
    }
    .inner_boxes img {
        width: 35px;
        margin: 0 auto 6px;
    }
    .inner_boxes b {
        font-size: 13px;
    }
    .balance_box p {
        font-size: 12px;
        margin: 3px 0 9px;
    }
    .cash_flow {
        padding: 0 8px 0;
    }
    .account_bln {
        margin: 0 4px 11px;
        padding: 8px 0;
    }
    .balance_box b {
        font-size: 21px;
    }
    .balance_box p {
        margin: 3px 0 9px;
    }
    .cashflow_month b,.balance_box p {
        font-size: 11px;
    }
    .cash_box{
        width: 100% !important;
        float: none;
    }
    .cashflow_month p {
        font-size: 11px;
    }
    .cashflow_month {
        padding: 2px 0;
        text-align: center;
        float: left;
        width: 33%;
    }
    .cashflow_month b{
        font-weight: 800;
    }
    ul.tabs li a:after {
        border-left: solid transparent 19px;
        border-right: solid transparent 19px;
        border-top: solid #4d4d4d 21px;
        top: 8px;
        right: -29px;
    }
    #pos_total .total_amount_box span {
        padding: 7px 0 0;
    }
    .dashboard_body {
        /*background: url(/static/media/pos-banner.a75ad210.jpg);*/
        background-image: linear-gradient(
    -225deg
     ,#2f80d4 35%,#337fce 100%);
    }

    #invoice_listing_ftr button img {
        width: 27px;
    }
   /* .pos_inv_table {
        padding-bottom: 0px;
    }*/
    .invoice_bdr {
        padding: 8px 9px;
    }
    #invoice_listing_p .left_invoice p {
        font-size: 14px;
    }
    .left_invoice, .right_invoice {
        padding: 0;
        max-width: 50%;
    }
    #invoice_listing_p .invoice-space {
        margin-bottom: 0;
        border: none;
        background: none;
        padding: 0;
        text-align: right;
    }

   #invoice_listing_p .invoice-space span {
        margin: 0 7px 0 0;
        width: 34%;
        font-size: 15px;
        font-weight: 500;
    }
    #invoice_listing_p .invoice-space b{
        width: 33%;
        display: inline-block;
        padding:0 8px 0 0;
        font-weight: 800;
        font-size: 14px;
    }
    .total_hide{
        display: none !important;
    }
    .img_tooltip_cart{
        position: absolute;
        width: 20px;
        top: 15px;
        left: 164px;
    }
    .next_prev {
    padding: 12px 0 0 !important;
}

}

@media(max-width: 410px){
    .container{
        width: 320px;
    }
    .img_tooltip_cart{
        position: absolute;
        width: 20px;
        top: 15px;
        left: 164px;
    }
    .cashreceive{
        padding-top: 26px;
    }
    .products_listing ul {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
    .products_listing ul li {
        width: 100%;
    }
    .products_listing .product_box h4 {
        font-size: 16px;
    }
    .quantity_box_pos .minus_plus_pos{
        font-size: 19px;
    }
    .quantity_box_pos .minus_plus_pos,.quantity_box_pos .value_box_pos {
        height: 32px;
        width: 32px;
    }
    .products_listing .product_box .product_label {
        width: 82px;
        height: 82px;
    }
    #select_pro .quantity_box_pos .minus_plus_pos {
        font-size: 17px;
        height: 24px !important;
        width: 24px !important;
    }
}
@media print {
   body {
      -webkit-print-color-adjust: exact;
   }
}
